import { Locales } from '../../interfaces';

const esAL: Locales = {
  countries: {
    AQ: 'Antártida',
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Bélgica',
    BO: 'Bolivia',
    BR: 'Brasil',
    CA: 'Canadá',
    IC: 'Islas Canarias',
    CL: 'Chile',
    CO: 'Colombia',
    CZ: 'República Checa',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    SV: 'El Salvador',
    DE: 'Alemania',
    HN: 'Honduras',
    IN: 'India',
    ID: 'Indonesia',
    MX: 'México',
    NL: 'Países Bajos',
    NG: 'Nigeria',
    PA: 'Panamá',
    PY: 'Paraguay',
    PE: 'Perú',
    PH: 'Filipinas',
    ZA: 'Sudáfrica',
    KR: 'Corea del Sur',
    TZ: 'Tanzania',
    TH: 'Tailandia',
  },
  select: {
    country: 'Seleccione un país',
    vendor: 'Seleccione un proveedor',
  },
  routes: {
    credit: 'Crédito',
    upload: 'Cargar archivo',
    progress: 'Progreso del archivo',
    pocDetails: 'Detalles del PDV',
    files: 'Archivos de PDV',
    views: 'Vista del cliente',
  },
  pages: {
    credit: {
      title: 'Gestión de crédito',
      summary: {
        total: 'PDVs totales',
        pending: 'Aprobaciones pendientes',
        inprogress: 'En curso',
        active: 'Crédito activo',
        denied: 'Crédito denegado',
      },
    },
    files: {
      title: 'Archivos de PDV',
    },
    upload: {
      title: 'Cargar archivo',
      uploader: {
        title: 'Carga de archivo',
        description: `
          La información del PDV existente se actualizará de acuerdo con el archivo cargado. Los nuevos PDV agregados al archivo no se incluirán en la lista de PDV.\n
          La carga puede tardar algún tiempo en procesarse. Una vez finalizada la carga, el usuario puede salir de la página y regresar más tarde haciendo clic en el archivo en la página de Archivos PDV."
        `,
        fileUploader: {
          label: 'Arrastra o examina tu archivo',
          description:
            'Arrastra y suelta el archivo aquí para cargarlo. El formato del archivo debe ser XLSX.',
        },
        fileDescription: {
          title: 'Descripción del archivo',
          subTitle: '(opcional)',
          description:
            'Haz que este archivo sea más fácil de identificar proporcionando algunos detalles.',
        },
      },
      buttons: {
        back: 'Volver',
        upload: 'Cargar archivo',
        apply: 'Actualizar PDV',
        goToCredit: 'Ir al crédito',
        goToFiles: 'Ir a archivos',
        toasts: {
          analysis: 'Se actualizaron correctamente { validated } PDV.',
          processing: 'Archivo en procesamiento. { fileName }',
          success: '¡El archivo se cargó correctamente! { fileName }',
          warning: 'Archivo cargado con errores. { fileName }',
          error: 'Error de carga. { fileName }',
        },
      },
      progress: {
        title: 'Análisis del archivo cargado',
        processingTitle: 'Actualización de condiciones y estatus crediticio',
        meter: {
          starting: 'Inicio',
          progress: '{ progressValue } de { progressMaxValue } PDV procesados.',
          description:
            'El tiempo de análisis puede ser más largo cuando el archivo contiene muchos PDV.',
          descriptionProgressing:
            'El tiempo de procesamiento puede ser más largo cuando hay muchos PDV para actualizar.',
          error: 'El archivo no contiene PDV válidos. Revisa el archivo y cárgalo nuevamente.',
          slow: 'La carga está tardando más de lo normal. Espera un momento.',
        },
        finished: {
          pocs: {
            success: 'Los PDV están listos para ser agregados',
            error: 'PDV con error',
            tooltip:
              'Solo es posible aumentar el límite de crédito al 200% del monto sugerido, y el plazo máximo permitido es de 30 días.',
          },
          alert:
            'Descargar la lista de errores y cargar un archivo nuevo corregido en el paso anterior o continuar con solo los PDV listos para agregar.',
          download: 'Descargar lista de errores',
        },
        error: {
          analysis:
            'No fue posible completar el análisis del archivo. Carga el archivo nuevamente.',
          processing: 'No se puede completar la actualización. Vuelva a cargar el archivo.',
        },
      },
    },
    'poc-details': {
      recommendation: {
        title: 'Recomendación de línea de crédito',
        status: {
          pending: 'Pendiente',
          denied: 'Denegado',
          waitingActivation: 'En espera de activación',
          waitingOnboarding: 'En espera de onboarding',
        },
        labels: {
          creditLimit: 'Límite de crédito',
          termFee: 'Plazo/tarifa',
          recommendation: 'Recomendación',
          updatedOn: 'Actualizado el',
          approvedBy: 'Editado por',
          approvalMethod: 'Editar método',
        },
        timeline: {
          title: 'Plazo de recomendación',
        },
      },
      information: {
        title: 'Información de PDV',
        labels: {
          pocName: 'Nombre',
          pocDocument: 'ID',
          pocAddress: 'Dirección',
          pocEmail: 'Correo electrónico',
          pocPhoneNumber: 'Número de teléfono',
        },
      },
      conditions: {
        title: 'Condiciones actuales de la línea de crédito',
        status: {
          active: 'Activo',
          blocked: 'Bloqueado',
          overdue: 'Atrasado',
          due: 'Vencido',
        },
        labels: {
          creditLimit: 'Límite de crédito',
          limitAvailable: 'Límite disponible',
          balance: 'Línea utilizada',
          overdue: 'Atrasado',
          termFee: 'Plazo/tarifa',
          paymentMethod: 'Forma de pago',
          activatedOn: 'Activado el',
          approvedBy: 'Aprobado por',
          approvalMethod: 'Método de aprobación',
          fileName: 'Nombre del archivo',
          fileDescription: 'Descripción del archivo',
        },
      },
      history: {
        credit: {
          title: 'Historial de crédito',
          description:
            'Todos los cambios de estado realizados a las condiciones de crédito del PDV.',
          buttons: { trigger: 'Mostrar historial de crédito' },
          information: {
            resume: '{ limit } - { term } - { fee }',
            by: 'Por { identifier }',
          },
          labels: {
            byAndHour: 'por { identifier } en { hour }',
            creditLimit: 'Límite de crédito:',
            termFee: 'Plazo/tarifa:',
            method: {
              edit: 'Editar método:',
              approval: 'Método de aprobación:',
              denial: 'Método de denegación:',
            },
          },
          status: {
            newCreditRecommendation: 'Nueva recomendación crediticia',
            editedSuggestion: 'Sugerencia de crédito editada',
            creditConditionsEdited: 'Condiciones actuales de crédito editada',
            approvedRecommendation: 'Recomendación de crédito aprobada',
            recommendationDenied: 'Recomendación de crédito denegada',
            creditLineBlocked: 'Línea de crédito bloqueada por falta de pago',
            creditLineUnblocked: 'Línea de crédito desbloqueada',
            creditLineActivated: 'Línea de crédito activada',
          },
        },
      },
    },
    views: {
      title: 'Vista del cliente',
      description:
        'Crea visualizaciones personalizadas en la tabla según tus requisitos de análisis.',
      buttons: { back: 'Voltar atrás', save: 'Guardar y aplicar' },
      toasts: {
        warning: 'Puedes agregar un máximo de 6 columnas a una tabla.',
        success: '{ name } se ha guardado y aplicado a la tabla.',
      },
      inputs: {
        name: {
          label: 'Ver nombre',
          placeholder: 'Ingresar nombre',
        },
        type: {
          label: 'Tipo de vista',
          options: {
            RECOMMENDED: {
              label: 'Recomendaciones de crédito',
              tooltip: 'PDV marcados por el algoritmo como buenos candidatos para crédito.',
            },
            NOT_RECOMMENDED: {
              label: 'PDV sin recomendación de crédito',
              tooltip: 'PDV no marcados por el algoritmo como buenos candidatos para crédito.',
            },
          },
        },
      },
      columns: {
        select: { description: 'Selecciona hasta 6 columnas para mostrar en la tabla.' },
        order: {
          description: 'Ordena las columnas seleccionadas según el orden de tu preferencia.',
        },
      },
    },
  },
  formatting: {
    datetime: { 'long-time': `{ date } en { time }` },
    input: {
      text: {
        percentage: '{ value } %',
        days: '{value, plural, =1 {# día} other {# días}}',
      },
      suffix: {
        percentage: '%',
        days: '{value, plural, =1 {día} other {días}}',
      },
    },
    recommendation: {
      NEW_CREDIT_OFFER: 'Oferta de crédito',
      INCREASE: 'Aumento de crédito',
      DECREASE: 'Disminución del crédito',
    },
    'approval-method': {
      FILE_BATCH_UPDATE: 'Por archivo',
      SYSTEM: 'Por sistema',
    },
    'payment-method': {
      CASH: 'Efectivo',
      CREDIT: 'Línea disponible',
      BANK_SLIP: 'Orden de pago',
      BANK_SLIP_INSTALLMENT: 'Orden de pago cuota',
    },
    'term-fee': '{term, plural, =1 {# día} other {# días}} / {fee}',
  },
  tables: {
    loading: 'Cargando artículos...',
    empty: 'Aún no hay clientes en esta lista.',
    search: 'Buscar por código o nombre de PDV',
    pagination: {
      quantity: 'de',
      itensPerPage: 'Artículos por página: { numberPage }',
    },
    selectable: {
      selected: { singular: 'cliente seleccionado', plural: 'clientes seleccionados' },
      deselect: 'Anular la selección de todo',
    },
    views: {
      selector: { label: 'Visualización: { name }' },
      card: {
        custom: { title: 'Vistas del cliente' },
        alert:
          'Has alcanzado el límite de 10 vistas personalizadas. Elimina una vista para crear una nueva.',
        standard: {
          title: 'Vistas estándar',
          DEFAULT: 'Estándar',
          RECOMMENDED: 'Recomendaciones de crédito',
          NOT_RECOMMENDED: 'PDV sin recomendación de crédito',
        },
        buttons: { create: 'Crear nueva vista personalizada' },
        tooltips: { edit: 'Editar la vista', delete: 'Eliminar la vista' },
        modal: {
          title: '¿Eliminar vista personalizada?',
          description: 'Esta acción no se puede deshacer.',
          buttons: { cancel: 'No, volver', delete: 'Sí, eliminar' },
          toasts: { success: 'Tu vista personalizada ha sido eliminada.' },
        },
      },
    },
    filters: {
      empty: 'Sin resultados de filtro.',
      buttons: {
        filter: '{value, plural, =0 {Filtro} one {Filtro (1)} other {Filtros (#)}}',
        cancel: 'Cancelar',
        apply: 'Aplicar',
        clearAll: 'Borrar todo',
        clearDate: 'Borrar fecha',
      },
      chips: {
        min: 'Mínimo { min }',
        max: 'Máximo { max }',
        startDate: 'Fecha de inicio { min }',
        endDate: 'Fecha de finalización { max }',
        to: '{ min } a { max }',
      },
      inputs: {
        updatedAt: {
          name: 'Última actualización',
          min: { label: 'Fecha de inicio', placeholder: 'Insertar la fecha' },
          max: { label: 'Fecha de finalización', placeholder: 'Insertar la fecha' },
        },
        creditLimit: {
          name: 'Límite de crédito',
          min: { label: 'Límite mínimo', placeholder: 'Ingresa el valor' },
          max: { label: 'Límite máximo', placeholder: 'Ingresa el valor' },
        },
        fee: {
          name: 'Cargo',
          min: { label: 'Cargo mínimo', placeholder: '0' },
          max: { label: 'Cargo máximo', placeholder: '0' },
        },
        term: {
          name: 'Plazo',
          min: {
            label: 'Plazo mínimo',
            placeholder: '0',
            sufix: '{value, plural, =1 {día} other {días}}',
          },
          max: {
            label: 'Plazo máximo',
            placeholder: '0',
            sufix: '{value, plural, =1 {día} other {días}}',
          },
        },
        status: {
          name: 'Estado',
          title: 'Estado',
          labels: {
            processing: 'Procesando',
            integration: 'Incorporación',
            activated: 'Activo',
            denied: 'Denegado',
          },
        },
        recommendation: {
          name: 'Recomendación',
          title: 'Recomendación',
          labels: {
            creditOffer: 'Oferta de crédito',
            creditIncrease: 'Aumento de crédito',
            creditDecrease: 'Disminución del crédito',
          },
        },
      },
    },
    files: {
      tab: { title: 'Arquivos' },
      empty: 'Aún no hay archivos en esta lista.',
      search: 'Buscar por nombre de archivo',
      status: {
        pending: 'Pendiente',
        uploaded: 'Subido',
        analyzing: 'Analizando',
        analyzed: 'Analizado',
        processing: 'Procesando',
        success: 'Cargado',
        errors: 'Cargado con errores',
      },
      columns: {
        name: 'Nombre del archivo',
        totalPOCs: 'PDVs actualizados',
        description: 'Descripción del archivo',
        uploadedBy: 'Cargado por',
        uploadedAt: 'Actualizado el',
        status: 'Estado',
        actions: 'Acción',
      },
    },
    pending: {
      tab: { title: 'Pendiente' },
      columns: {
        pocName: 'PDV',
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        term: 'Plazo',
        fee: 'Cargo',
        score: 'Puntaje',
        recommendation: 'Recomendación',
        updatedAt: 'Última actualización',
        actions: 'Acción',
        salesAgency: 'Agencia de ventas',
        salesArea: 'Área de ventas',
        customerType: 'Tipo de cliente',
      },
    },
    inprogress: {
      tab: { title: 'En curso' },
      status: {
        processing: 'Procesando',
        integration: 'Incorporación',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        term: 'Plazo',
        fee: 'Cargo',
        updatedAt: 'Última actualización',
        updatedBy: 'Actualizado por',
        status: 'Estado',
      },
    },
    completed: {
      tab: { title: 'Completado' },
      status: {
        active: 'Activo',
        denied: 'Denegado',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        term: 'Plazo',
        fee: 'Cargo',
        updatedAt: 'Última actualización',
        updatedBy: 'Actualizado por',
        status: 'Estado de crédito',
      },
    },
  },
  files: {
    buttons: {
      upload: 'Cargar archivo',
      download: 'Descargar archivo',
    },
    modal: {
      title: 'Descargar archivo',
      description:
        'Descarga los archivos para aprobar o denegar líneas de crédito de clientes. Los archivos no pueden contener:',
      itemsList: {
        first: 'Columnas agregadas o renombradas',
        second: 'Clientes agregados o renombrados',
        third:
          'Cualquier información que se haya agregado o modificado, excepto el estado de aprobación',
      },
      fileDetails: {
        title: 'Detalles del archivo',
        description: 'Selecciona la información que desea incluir en el archivo.',
        subTitle: 'Estado del cliente:',
        subDescription: 'Selecciona los estados que desea incluir en el archivo.',
        checkboxes: {
          all: 'Todos',
          pending: 'Aprobación pendiente  ',
          approved: 'Crédito aprobado',
          denied: 'Crédito denegado',
        },
      },
      buttons: {
        back: 'Volver',
        confirm: 'Generar y descargar archivo',
      },
      toasts: {
        info: 'El archivo se está generando y puede tardar unos segundos. Se descargará automáticamente cuando termine.',
      },
    },
    errors: {
      412: {
        description:
          'Ya hay otro archivo en proceso. Debes esperar hasta que termine antes de cargar un nuevo archivo.',
      },
    },
  },
  editRecommendation: {
    title: 'Editar recomendación de crédito',
    description:
      'Las recomendaciones se basan en el análisis de datos de PDV. Al editar una recomendación de crédito, debes seguir ciertas condiciones.',
    buttons: {
      cancel: 'Cancelar',
      apply: 'Aplicar cambios',
      tooltip: 'Editar estado',
      edit: { recommendation: 'Editar recomendación', conditions: 'Editar condiciones' },
    },
    toasts: { success: 'Se cambiaron las condiciones crediticias de { value }.' },
    inputs: {
      creditLimit: {
        label: 'Límite de crédito',
        hint: 'Sugerido: { value }.',
      },
      term: {
        label: 'Plazo de crédito',
        hint: 'Sugerido: {value, plural, =1 {# día} other {# días}}.',
      },
      fee: {
        label: 'Cargo por crédito',
      },
    },
  },
  approveRecommendation: {
    title: '¿Aprobar la recomendación crediticia?',
    description: {
      single: 'Selecciona la opción para este PDV.',
      multiple: 'Selecciona una opción para todos los PDV seleccionados.',
    },
    counter: 'Se seleccionaron { counter } PDV',
    info: {
      title: 'Solicitar incorporación de PDV',
      description:
        'Se solicitará el número de teléfono, la identificación del PDV, el comprobante de domicilio y la aceptación de los términos y condiciones.',
    },
    radios: { request: 'Solicitud', doNotRequest: 'No solicitar' },
    buttons: { back: 'No, volver', approve: 'Sí, aprobar' },
    warning: 'Si no solicitas la incorporación, asumirás la responsabilidad de esta decisión.',
  },
  offerCredit: {
    title: '¿Ofrecer crédito?',
    tooltip: 'Ofrece crédito.',
    warning:
      'En función de la información disponible, no hay recomendación de crédito para este PDV. Cualquier decisión de crédito que se tome se tomará a tu propia discreción.',
    info: {
      title: 'Solicitar incorporación de PDV',
      description:
        'Se solicitará el número de teléfono, la identificación del PDV, el comprobante de domicilio y la aceptación de los términos y condiciones.',
    },
    radios: { request: 'Solicitud', doNotRequest: 'No solicitar' },
    buttons: {
      cancel: 'Regresar',
      apply: 'Ofrecer crédito',
    },
    toasts: { success: 'Crédito ofrecido a { value }.' },
    inputs: {
      creditLimit: {
        label: 'Límite de crédito',
        placeholder: 'Ingresar un valor',
      },
      term: {
        label: 'Plazo de crédito',
        placeholder: 'días',
      },
      fee: {
        label: 'Cargo por crédito',
      },
    },
  },
  loading: {
    default: 'Te llevamos a la pantalla',
    optimizely: 'Preparando tu experiencia',
  },
  errors: {
    default: 'Algo salió mal.',
    generic: 'Algo salió mal. Intenta volver a cargar la página.',
    403: {
      title: 'Hmm... Acceso denegado!',
      description: 'Lamentablemente, no tienes permiso para acceder a esta página.',
      button: 'Volver',
    },
    404: {
      title: 'Hmm… Esta página parece haber desaparecido!',
      description: 'Esta página no se pudo encontrar o no existe.',
      button: 'Volver',
    },
    500: {
      title: 'Ooops! Parece que algo salió mal',
    },
    503: {
      title: 'Ooops! Parece que algo salió mal',
      description:
        'Estamos teniendo algunos problemas para cargar esta página. Inténtalo de nuevo.',
      info: '',
      button: 'Inténtalo de nuevo',
    },
    custom: {
      source: {
        title: 'Ooops!',
        description: 'Algo salió mal. Intenta volver a cargar la página.',
        button: 'Inténtalo de nuevo',
      },
    },
  },
  source: {
    validations: {
      errors: {
        'error:term:zero': 'Debe ser de {value, plural, =1 {# día} other {# días}} como mínimo.',
        'error:term:small': 'Debe tener un máximo de {value, plural, =1 {# día} other {# días}}.',
        'error:term:fee:big': 'Debe ser del { value }% como máximo.',
        'error:term:fee:negative': 'Debe ser igual o mayor que el { value }%.',
        'error:limit:big': 'Máximo del { value }% del monto recomendado.',
        'error:limit:small': 'Debe ser igual o mayor que el { value }.',
      },
    },
  },
  approval: {
    buttons: {
      APPROVED: {
        single: 'Aprobar',
        multiple: 'Aprobar seleccionado',
        tooltip: 'Aprobar línea de crédito',
      },
      DENIED: {
        single: 'Rechazar',
        multiple: 'Denegar seleccionados',
        tooltip: 'Denegar línea de crédito',
      },
    },
    toasts: {
      APPROVED: {
        single: 'La línea de crédito de { value } está aprobada.',
        multiple: '¡La línea de crédito para el { value } cliente está aprobada!',
      },
      DENIED: {
        single: 'La línea de crédito de { value } ha sido denegada.',
        multiple: '¡Se deniega la línea de crédito para { value } clientes!',
      },
    },
    errors: {
      412: {
        description:
          'Ya hay una actualización en proceso. Debes esperar hasta que finalice antes de realizar una nueva actualización.',
      },
    },
  },
  timeline: {
    approved: 'Aprobado',
    onboarding: 'Incorporación',
    activationProcess: 'Proceso de activación',
    creditLineActivated: 'Línea de crédito activada',
  },
};

export default esAL;

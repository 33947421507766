import { Filter as FilterIcon } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { ClearAllButton } from '../../../ClearAllButton/ClearAllButton';
import { CollapseButton } from '../../../CollapseButton/CollapseButton';
import { useFilter } from '../Context/FilterContext';
import { FilterCard } from './Card/FilterCard';
import { RootProps } from './FilterRoot.types';
import { RootContainer } from './styles';

export const FilterRoot: React.FC<RootProps> = ({ filters, onFilterFn, onClearFn }) => {
  const { formatMessage } = useIntl();

  const { open, counter } = useFilter();

  return (
    <RootContainer data-testid="filter-root-container">
      <CollapseButton
        open={open}
        disabled={!filters.length}
        label={formatMessage({ id: 'tables.filters.buttons.filter' }, { value: counter })}
        icon={FilterIcon}
      >
        <FilterCard filters={filters} onFilterFn={onFilterFn} onClearFn={onClearFn} />
      </CollapseButton>

      {counter > 0 && <ClearAllButton />}
    </RootContainer>
  );
};

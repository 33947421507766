import { Breadcrumb, Heading, IconButton, Select } from '@hexa-ui/components';
import { ArrowLeft, World } from '@hexa-ui/icons';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathnameRegex } from '../../../constants';
import { useHeader } from '../../../hooks/useHeader';
import SegmentService from '../../../services/segment';
import { AnalyticsScriptTag } from '../../atoms/AnalyticsScriptTag/AnalyticsScriptTag';
import { ButtonsContainer, Container, HeaderContent, Link, TitleContainer } from './styles';

export const AppHeader: React.FC = () => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { title, country, vendorId, countryOptions, vendorOptions, breadcrumbs, setAppHeader } =
    useHeader();

  const onNavigate = (path: string) => navigate(path);

  const onBack = () => {
    if (pathnameRegex.pocDetails.test(pathname)) {
      navigate('/bees-credit-management/credit');
    } else {
      navigate(-1);
    }
  };

  const filteredVendors = useMemo(() => {
    return vendorOptions.vendors.filter((vendor) => vendor.country === countryOptions.value);
  }, [vendorOptions.vendors, countryOptions.value]);

  const onChangeCountry = (value: string) => {
    SegmentService.paymentsButtonClicked(
      'Country',
      'Country',
      vendorId,
      'Credit Management Country Selection',
      `CREDIT_MANAGEMENT_CREDIT_PAGE`
    );

    const { item, vendors } = vendorOptions.vendors.reduce(
      (acc, vendor) => {
        if (vendor.country === value) {
          acc.vendors.push(vendor.id);

          if (!acc.item) acc.item = vendor.id;
        }

        return acc;
      },
      { item: '', vendors: [] }
    );

    if (vendors.includes(vendorId)) {
      setAppHeader({ country: value });

      return;
    }

    setAppHeader({ country: value, vendorId: item });
  };

  const onChangeVendor = (value: string) => {
    SegmentService.paymentsButtonClicked(
      'Vendor',
      'Vendor',
      value,
      'Credit Management Vendor Selection',
      'CREDIT_MANAGEMENT_CREDIT_PAGE'
    );

    const { item, countries } = vendorOptions.vendors.reduce(
      (acc, vendor) => {
        if (vendor.id === value) {
          acc.countries.push(vendor.country);

          if (!acc.item) acc.item = vendor.country;
        }

        return acc;
      },
      { item: '', countries: [] }
    );

    if (countries.includes(country)) {
      setAppHeader({ vendorId: value });

      return;
    }

    setAppHeader({ country: item, vendorId: value });
  };

  return (
    <Container sidebar type="fluid">
      <HeaderContent $hasbreadcrumbs={breadcrumbs.items.length > 0}>
        {breadcrumbs.items.length > 0 && (
          <Breadcrumb.Root>
            <Breadcrumb.HomeItem asChild>
              <Link onClick={() => onNavigate(breadcrumbs.homePath)} data-testid="home-item-link" />
            </Breadcrumb.HomeItem>

            {breadcrumbs.items.map((item) => (
              <React.Fragment key={item.label}>
                <Breadcrumb.Item isCurrentPage={item.isCurrentPage}>
                  <Link onClick={() => onNavigate(item.path)} data-testid="item-link">
                    {formatMessage({ id: item.label })}
                  </Link>
                </Breadcrumb.Item>
              </React.Fragment>
            ))}
          </Breadcrumb.Root>
        )}

        {pathnameRegex.credit.test(pathname) && (
          <ButtonsContainer>
            <Select.Root
              id="select-country"
              value={countryOptions.value || 'select-country'}
              shape="pill"
              size="small"
              icon={<World size="medium" />}
              onChange={onChangeCountry}
            >
              <Select.Option value="select-country" disabled>
                {formatMessage({ id: 'select.country' })}
              </Select.Option>

              {countryOptions.countries?.map((country) => (
                <Select.Option key={`${country.id}-${country.name}`} value={country.id}>
                  {country.name}
                </Select.Option>
              ))}
            </Select.Root>

            <Select.Root
              id="select-vendor"
              value={vendorOptions.value || 'select-vendor'}
              shape="pill"
              size="small"
              onChange={onChangeVendor}
            >
              <Select.Option value="select-vendor" disabled>
                {formatMessage({ id: 'select.vendor' })}
              </Select.Option>

              {filteredVendors?.map((vendor) => (
                <Select.Option key={`${vendor.id}-${vendor.country}`} value={vendor.id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select.Root>
          </ButtonsContainer>
        )}
      </HeaderContent>

      <TitleContainer>
        {Boolean(breadcrumbs.items.length > 1 && title.value !== 'pages.credit.title') && (
          <IconButton icon={ArrowLeft} variant="tertiary" size="medium" onClick={onBack} />
        )}

        <Heading size="H2">
          {title.translate ? formatMessage({ id: title.value }) : title.value}
        </Heading>
      </TitleContainer>

      <AnalyticsScriptTag />
    </Container>
  );
};

import { Paragraph, ProgressTracker } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { ProgressUploadSteps } from '../../../../interfaces';
import { useUpload } from '../Context';

export const ProgressUpload: React.FC = () => {
  const { formatMessage } = useIntl();
  const { progress } = useUpload();
  const { status } = progress.value;

  const stepMap = {
    PENDING: ProgressUploadSteps.FILE_UPLOAD,
    UPLOADED: ProgressUploadSteps.FILE_UPLOAD,
    ANALYZING: ProgressUploadSteps.FILE_ANALYSIS,
    ANALYZED: ProgressUploadSteps.FILE_ANALYSIS,
    PROCESSING: ProgressUploadSteps.POC_UPDATE,
    DONE: ProgressUploadSteps.POC_UPDATE,
    FAILED: ProgressUploadSteps.FILE_ANALYSIS,
  };

  const currentStep = stepMap[status] || ProgressUploadSteps.FILE_UPLOAD;
  const completedSteps = Object.values(ProgressUploadSteps).filter((step) => step < currentStep);

  const Step = ({ index, messageId, completed }) => (
    <ProgressTracker.Step index={index} completed={completed}>
      <Paragraph>{formatMessage({ id: messageId })}</Paragraph>
    </ProgressTracker.Step>
  );

  return (
    <ProgressTracker.Root currentStep={currentStep} orientation="horizontal" size="large">
      <ProgressTracker.Header>
        <Step
          index={ProgressUploadSteps.FILE_UPLOAD}
          messageId="pages.upload.uploader.steps.fileUpload"
          completed={completedSteps.includes(ProgressUploadSteps.FILE_UPLOAD)}
        />
        <Step
          index={ProgressUploadSteps.FILE_ANALYSIS}
          messageId="pages.upload.uploader.steps.fileAnalysis"
          completed={completedSteps.includes(ProgressUploadSteps.FILE_ANALYSIS)}
        />
        <Step
          index={ProgressUploadSteps.POC_UPDATE}
          messageId="pages.upload.uploader.steps.pocUpdate"
          completed={completedSteps.includes(ProgressUploadSteps.POC_UPDATE)}
        />
      </ProgressTracker.Header>
    </ProgressTracker.Root>
  );
};

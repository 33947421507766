import { UploadableFile } from '@hexa-ui/components';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { GetSet, ProgressProps, UploadedProps } from '../../../../interfaces';

interface UploadContext {
  files: GetSet<UploadableFile[]>;
  description: GetSet<string>;
  uploaded: GetSet<UploadedProps>;
  progress: GetSet<ProgressProps>;
  uploadError: GetSet<boolean>;
  uploadStarted: GetSet<boolean>;
  processingStarted: GetSet<boolean>;
  onClear: () => void;
  shouldDisplayError: GetSet<boolean>;
}

interface UploadProviderProps {
  children: React.ReactNode;
  fileId?: string;
  fileName?: string;
}

const UploadContext = createContext<UploadContext | null>(null);

export const UploadProvider: React.FC<UploadProviderProps> = ({ children, fileId, fileName }) => {
  const [files, setFiles] = useState<UploadableFile[] | null>([]);
  const [description, setDescription] = useState<string>('');
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const [processingStarted, setProcessingStarted] = useState<boolean>(false);
  const [shouldDisplayError, setShouldDisplayError] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<UploadedProps>({
    fileId: fileId ?? '',
    fileName: fileName ?? '',
  });
  const [progress, setProgress] = useState<ProgressProps>({
    status: '',
    total: 100,
    totalAnalysis: 0,
    validated: 0,
    failed: 0,
    processed: 0,
  });

  const onClear = useCallback(() => {
    setFiles([]);
    setDescription('');
    setUploaded({ fileId: '', fileName: '' });
    setProgress({
      status: '',
      total: 100,
      totalAnalysis: 0,
      validated: 0,
      failed: 0,
      processed: 0,
    });
    setUploadError(false);
    setShouldDisplayError(false);
    setUploadStarted(false);
    setProcessingStarted(false);
  }, []);

  const value = useMemo(() => {
    return {
      files: { value: files, setter: setFiles },
      description: { value: description, setter: setDescription },
      uploaded: { value: uploaded, setter: setUploaded },
      progress: { value: progress, setter: setProgress },
      uploadError: { value: uploadError, setter: setUploadError },
      uploadStarted: { value: uploadStarted, setter: setUploadStarted },
      processingStarted: { value: processingStarted, setter: setProcessingStarted },
      shouldDisplayError: { value: shouldDisplayError, setter: setShouldDisplayError },
      onClear,
    };
  }, [
    files,
    description,
    uploaded,
    progress,
    uploadError,
    uploadStarted,
    processingStarted,
    shouldDisplayError,
  ]);

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useUpload = () => useContext(UploadContext);
